<template lang="pug">
.col-4.my-2
  .gfonts-wrapper
    .gfonts-header.d-flex
      .gfonts-header-family {{ font.family }}
    textarea.gfonts-example(
      :style="`font-family:'${font.family}';width:100%;resize:none;height:10rem;font-size:1.5em;border:none;outline:none;padding:.5em`"
    ) {{ $t('fontManager.example.text') }}
</template>

<script>
  export default {
    props: ['font'],
  };
</script>
