<template lang="pug">
  infinite-loading(:identifier="id || +(new Date())" @infinite="$emit('infinite', $event)" :force-use-infinite-wrapper="wrapper")
    div(slot="no-results")
    div(slot="no-more")
    div(slot="spinner")
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    components: { InfiniteLoading },
    props: ['wrapper', 'id'],
  };
</script>
