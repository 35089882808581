<template lang="pug">
om-modal.font-settings-box(
  :title="title"
  width="700px"
  name="font-settings"
  :scrollable="false"
  color="light"
  @beforeOpen="init"
)
  template(slot="modal-body")
    .font-settings.d-flex(v-if="font")
      .font-settings-subsets
        .font-settings-subsets-title {{ $t('fontManager.subsetsText') }}
        .font-settings-subsets-item.d-flex.mt-2(v-for="(subset, i) in subsets")
          vue-switches.m-0.d-flex(
            v-model="subsets[i].selected"
            theme="om-orange"
            text-enabled="on"
            text-disabled="off"
            type-bold="true"
            style="flex-grow: 0; align-items: center"
          )
          .font-settings-subset-name.d-flex.ml-3(style="align-items: center") {{ subset.name }}
      .font-settings-variants.ml-5
        .font-settings-variants-title {{ $t('fontManager.variantsText') }}
        .font-settings-variants-item(
          v-for="v in font.availableVariants"
          :style="{ 'font-family': font.family, 'font-weight': v.weight, 'font-style': v.italic ? 'italic' : null }"
        ) {{ v.text }}
      .font-settings-example.ml-5
        .font-settings-example-title {{ $t('fontManager.example.title') }}
        .font-settings-example-content(:style="{ 'font-family': font.family }") {{ $t('fontManager.example.text') }}
  template(slot="modal-footer")
    .d-flex.justify-content-end(style="padding: 1.25rem")
      .brand-btn.brand-btn-secondary(@click="$modal.hide('font-settings')") {{ $t('close') }}
      .ml-4.brand-btn.brand-btn-primary(@click="save" :disabled="unableToSave") {{ $t(transKey || 'save') }}
</template>
<script>
  const c = (v) => JSON.parse(JSON.stringify(v));
  export default {
    data: () => ({
      subsets: [],
      unableToSave: true,
      transKey: null,
      font: null,
      weights: [],
    }),
    computed: {
      selected() {
        return this.subsets.filter((subset) => subset.selected);
      },
      atLeastOneSelected() {
        return this.selected.length > 0;
      },
      title() {
        if (!this.font) return;
        return `Configure '${this.font.family}'`;
      },
    },
    watch: {
      subsets: {
        handler() {
          this.unableToSave = !this.atLeastOneSelected;
        },
        deep: true,
      },
    },
    methods: {
      save() {
        if (this.unableToSave) {
          this.$notify({
            type: 'error',
            text: this.$t('fontManager.notifications.noSubset'),
          });
          return;
        }
        this.$emit('save', {
          key: this.font.key,
          subsets: c(this.selected.map((s) => s.subset)),
          weights: c(this.weights),
        });
        this.$modal.hide('font-settings');
      },
      isSelected(subset) {
        return this.font.installedSubsets
          ? this.font.installedSubsets.includes(subset)
          : ['latin', 'latin-ext'].includes(subset);
      },
      init({ params: { font, transKey, weights } }) {
        this.font = font;
        this.transKey = transKey;
        this.weights = weights;
        this.initSubsets();
      },
      initSubsets() {
        this.subsets = [];
        this.font.subsets.forEach((subset) => {
          // const key = `fontManager.subsets.${subset}`
          // const trans = this.$t(key)
          this.subsets.push({
            selected: this.isSelected(subset),
            name: subset, // key === trans ? subset : trans, // TODO make more translations
            subset,
          });
        });
      },
    },
  };
</script>

<style lang="sass">
  .font-settings-box
    &.v--modal-overlay
      height: 100% !important
      .brand-modal-body
        overflow-y: auto
        max-height: 300px
        padding: 1.5rem !important
  .font-settings-example-content
    word-break: break-word
</style>
