<template lang="pug">
.font-manager-search.d-flex.justify-content-start
  .font-manager-search-family.m-2.w-50
    multiselect(
      v-model="selected"
      :options="options"
      label="family"
      track-by="key"
      :multiple="true"
      :placeholder="$t('fontManager.search.placeholder.font')"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      open-direction="bottom"
    )
  .font-manager-search-subsets.m-2.w-50
    multiselect(
      v-model="subsets"
      :options="avSubsets"
      :multiple="true"
      :placeholder="$t('fontManager.search.placeholder.subsets')"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      open-direction="bottom"
    )
  .font-manager-search-icon.m-2.w-50
    .btn.brand-btn-primary.mr-2(v-if="selected.length || subsets.length" @click="onSearch") {{ $t('search') }}
    .btn.brand-btn-primary(v-if="clearable" @click="onClear") {{ $t('clear') }}
</template>

<script>
  const _c = (v) => JSON.parse(JSON.stringify(v));
  export default {
    props: {
      items: { required: true },
      initSelected: { default: () => [] },
      initSubsets: { default: () => [] },
    },
    data: () => ({
      selected: [],
      subsets: [],
      clearable: false,
    }),
    computed: {
      mappedItems() {
        return this.items.map((item) => {
          return {
            key: item.key,
            family: item.family,
            subsets: item.subsets,
          };
        });
      },
      options() {
        return this.mappedItems.filter((item) => {
          let result = true;
          if (this.subsets.length) {
            item.subsets.forEach((itemSubset) => {
              if (this.subsets.includes(itemSubset)) result = true;
            });
          }
          return result;
        });
      },
      avSubsets() {
        const uniqueSubsets = new Set();
        this.mappedItems.filter((item) => {
          const result = true;
          if (this.selected.length) {
            this.selected.forEach((selected) =>
              selected.subsets.forEach((subset) => uniqueSubsets.add(subset)),
            );
          } else if (!this.selected.length) {
            item.subsets.forEach((subset) => uniqueSubsets.add(subset));
          }
          return result;
        });
        return Array.from(uniqueSubsets);
      },
    },
    mounted() {
      this.clearable = this.initSelected.length || this.initSubsets.length;
      if (this.initSelected)
        this.selected = _c(this.items.filter((item) => this.initSelected.includes(item.key)));
      if (this.initSubsets) this.subsets = _c(this.initSubsets);
    },
    methods: {
      onSearch() {
        this.clearable = true;
        this.$emit('search', {
          selected: this.selected.map((selected) => selected.key),
          subsets: this.subsets,
        });
      },
      onClear() {
        this.clearable = false;
        this.selected = [];
        this.subsets = [];
        this.$emit('clear');
      },
    },
  };
</script>
