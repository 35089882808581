<template lang="pug">
.col-4.my-2
  .gfonts-wrapper
    .gfonts-header.d-flex
      .gfonts-header-family {{ font.family }}
      .gfonts-header-install.font-size-1--25.ml-auto(
        v-if="installable || configurable"
        :class="{ 'mr-2': configurable && deletable }"
      )
        i.fas.fa-plus-circle(
          v-if="installable"
          @click="$emit('add', { key: font.key, variants: variants })"
        )
        i.fas.fa-cog(
          v-if="configurable"
          @click="$emit('add', { key: font.key, variants: variants })"
        )
      .gfonts-header-delete.font-size-1--25(v-if="configurable && deletable")
        i.fas.fa-trash(@click="$emit('remove', font.key)")
    expandable(:title="$t('fontManager.variantsText')")
      template(slot="content")
        .gfonts-variants.gfonts-gray-box(:style="`font-family:'${font.family}'`")
          .gfonts-variants-text(
            v-for="v in variants"
            :style="{ 'font-weight': v.weight, 'font-style': v.italic ? 'italic' : null }"
          ) {{ v.text }}
    expandable(:title="$t('fontManager.subsetsText')")
      template(slot="content")
        .gfonts-subsets.gfonts-gray-box {{ subsetsText }}
    textarea.gfonts-example(
      :style="`font-family:'${font.family}';width:100%;resize:none;height:10rem;font-size:1.5em;border:none;outline:none;padding:.5em`"
    ) {{ $t('fontManager.example.text') }}
</template>

<script>
  import WebFontLoader from 'webfontloader';
  import { mapMutations } from 'vuex';

  export default {
    props: ['font'],
    data: () => ({
      subsets: [],
    }),
    computed: {
      variants() {
        return this.font.variants.map((variant) => {
          const rawWeight = /(\d+)/g.exec(variant) ? /(\d+)/g.exec(variant)[1] : '400';
          const weight = parseInt(rawWeight, 10);
          const italic = variant.indexOf('i') !== -1;
          let text;
          if (variant === '100') text = 'THIN 100';
          if (variant === '100italic') text = 'THIN 100 ITALIC';
          if (variant === '200') text = 'THIN 200';
          if (variant === '200italic') text = 'THIN 200 ITALIC';
          if (variant === '300') text = 'LIGHT 300';
          if (variant === '300italic') text = 'LIGHT 300 ITALIC';
          if (variant === 'regular') text = 'REGULAR 400';
          if (variant === 'italic') text = 'REGULAR 400 ITALIC';
          if (variant === '500') text = 'MEDIUM 500';
          if (variant === '500italic') text = 'MEDIUM 500 ITALIC';
          if (variant === '600') text = 'MEDIUM 600';
          if (variant === '600italic') text = 'MEDIUM 600 ITALIC';
          if (variant === '700') text = 'BOLD 700';
          if (variant === '700italic') text = 'BOLD 700 ITALIC';
          if (variant === '800') text = 'EXTRA-BOLD 800';
          if (variant === '800italic') text = 'EXTRA-BOLD 800 ITALIC';
          if (variant === '900') text = 'BLACK 900';
          if (variant === '900italic') text = 'BLACK 900 ITALIC';
          return { text, weight, italic };
        });
      },
      subsetsText() {
        return this.font.subsets
          .map((subset) => {
            // const key = `fontManager.subsets.${subset}`
            // const trans = this.$t(key)
            // return key === trans ? subset : trans
            return subset;
          })
          .join(', ');
      },
      installable() {
        if (this.font.preInstalled) return false;
        return !this.font.installedSubsets;
      },
      configurable() {
        return this.font.installedSubsets || this.font.preInstalled;
      },
      deletable() {
        return !this.font.preInstalled;
      },
    },
    mounted() {
      if (!this.font.status) {
        WebFontLoader.load({
          google: {
            families: [this.font.family], // add weights and subsets
          },
        });
        this.setFontStatus({ key: this.font.key, status: true });
      }
    },
    methods: {
      ...mapMutations(['setFontStatus']),
    },
  };
</script>
